interface MetaImage {
  feature_image?: string;
  og_image?: string;
  twitter_image?: string;
}
interface Metadata {
  [key: string]: {
    description?: string;
    image?: MetaImage;
    imageAlt?: string;
    keywords?: string[];
    noIndex: boolean;
    title: string;
    url: string;
  };
}
export const metadata: Metadata = {
  "404": {
    image: {},
    keywords: [],
    noIndex: false,
    title: "404 | YuLife",
    url: "https://yulife.com/404/",
  },
  aig: {
    description:
      "Find out more about how YuLife and AIG work together to provide you and your employees with the best group life insurance on the market.",
    image: {},
    imageAlt: "aig-partnership",
    keywords: [],
    noIndex: false,
    title: "AIG & YuLife Life Insurance | YuLife",
    url: "https://yulife.com/aig/",
  },
  app: {
    description:
      "Discover how the YuLife app can improve the wellbeing at your workplace. Inspire and motivate your employees with a benefits app that drives engagement.",
    image: {},
    imageAlt: "yulife-app",
    keywords: ["app", "wellbeing", "benefits", "rewards", "workplace"],
    noIndex: false,
    title: "The YuLife Employee App | YuLife",
    url: "https://yulife.com/features/employee-app",
  },
  blog: {
    description:
      "A blog that teaches you more about YuLife's unique benefits and insurance product — and how to bring holistic wellbeing into your workplace.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Blogs | YuLife",
    url: "https://yulife.com/blog/",
  },
  careers: {
    description:
      "YuLife is on a mission to inspire people to live their best lives every day, and we’re looking for the people to help us do that. Join the innovative insurance provider and the #1 rated employee benefit in the UK.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "Careers | YuLife",
    url: "https://yulife.com/careers/",
  },
  "case-studies": {
    description:
      "Discover how YuLife has helped transform wellbeing in the workplace for SME's and large enterprises all around the world.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Case Studies | YuLife",
    url: "https://yulife.com/case-studies/",
  },
  "contact-us": {
    description:
      "For any queries relating to YuLife and the products we offer, get in touch via the form provided, email us at hello@yulife.com or call 020 3870 2604.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Contact Us | Get in touch | YuLife",
    url: "https://yulife.com/contact-us/",
  },
  "cookie-policy": {
    description:
      "Read our Cookie Policy to understand how cookies work and how we use them effectively on the YuLife website to improve the customer’s online experience.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Cookie Policy | YuLife",
    url: "https://yulife.com/cookie-policy/",
  },
  "employee-assistance-programme": {
    description:
      "Employee Assistance Programme (EAP) from YuLife offers mental health counselling, financial and legal advice to support employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Employee Assistance Programmes from YuLife",
    url: "https://yulife.com/features/employee-assistance-programme/",
  },
  events: {
    description:
      "YuLife’s must-see webinars and in-person events with some of the insurance, wellbeing and business industry’s biggest names. Find out what’s coming next, and how to sign up.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Events | YuLife",
    url: "https://yulife.com/events/",
  },
  "get-a-quote": {
    description: "Get a quote and discover how YuLife app can improve wellbeing at your workplace.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "Get a Quote | YuLife",
    url: "https://yulife.com/get-a-quote/",
  },
  "get-a-quote-success": {
    description: "Get a quote and discover how YuLife app can improve wellbeing at your workplace.",
    image: {},
    keywords: [],
    noIndex: true,
    title: "Get a Quote Success | YuLife",
    url: "https://yulife.com/get-a-quote/success/",
  },
  "group-critical-illness": {
    description:
      "Group Critical Illness cover as an employee benefit protects your people and your business, if they become seriously ill – but with YuLife, you get more than just insurance. This is how it works.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-critical-illness.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-critical-illness.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-critical-illness.png",
    },
    imageAlt: "group-critical-illness",
    keywords: [],
    noIndex: false,
    title: "Group Critical Illness",
    url: "https://yulife.com/insurance/group-critical-illness/",
  },
  "death-in-service": {
    description:
      "Death in Service insurance is an important employee benefit – but with YuLife, your people get more than just life insurance. Find out how.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
    },
    imageAlt: "death-in-service",
    keywords: [],
    noIndex: false,
    title: "Death in Service Insurance | YuLife",
    url: "https://yulife.com/death-in-service/",
  },
  "group-income-protection": {
    description:
      "Group Income Protection as an employee benefit protects your people’s financial wellbeing – but with YuLife, you get more than just insurance. This is how it works.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-income-protection.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-income-protection.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592580339/featured_images/featured-income-protection.png",
    },
    imageAlt: "group-income-protection",
    keywords: ["technology", "platform", "insurance", "income protection", "benefits"],
    noIndex: false,
    title: "Group Income Protection | YuLife",
    url: "https://yulife.com/insurance/group-income-protection",
  },
  "group-life-insurance": {
    description:
      "Group Life Insurance is an important employee benefit – but with YuLife, your people get more than just insurance. Find out how.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592579540/featured_images/featured-group-life.png",
    },
    imageAlt: "group-life-insurance",
    keywords: [],
    noIndex: false,
    title: "Group Life Insurance | YuLife",
    url: "https://yulife.com/insurance/group-life-insurance/",
  },
  "hr-insights": {
    description:
      "Create mental health reports, monitor wellbeing trends and collect actionable metrics with HR Insights by YuLife. Click through and find out more.",
    image: {},
    imageAlt: "hr-insights",
    keywords: [],
    noIndex: false,
    title: "HR Insights | YuLife",
    url: "https://yulife.com/hr-insights/",
  },
  index: {
    description:
      "Meet YuLife. Group insurance, employee benefits, wellbeing, engagement and global rewards in one platform your people love, and use.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "YuLife | Employee Insurance, Benefits & Rewards",
    url: "https://yulife.com",
  },
  "inspire-lives": {
    description:
      "Swap regular group life cover for a life-enhancing and motivating perk that your employees will love. Get a free quote now!",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Inspire Lives | YuLife",
    url: "https://yulife.com/inspire-lives/",
  },
  "personal-life-insurance": {
    description:
      "Take control of your future with insurance you own. Never lose your progress in the Yuniverse - even if you leave your current employer, the YuLife app and benefits are yours to keep.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1637155037/featured_images/Personal_Life_Insurance.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1637155037/featured_images/Personal_Life_Insurance.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1637155037/featured_images/Personal_Life_Insurance.png",
    },
    keywords: [],
    noIndex: false,
    title: "Personal Life Insurance | YuLife",
    url: "https://yulife.com/press/",
  },
  press: {
    description:
      "See YuLife’s appearances on Business Insider, Forbes, TechCrunch, Crunchbase, Reuters, The Sunday Times, The Independent, The Telegraph, Sifted and more...",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "Press Releases & News | YuLife",
    url: "https://yulife.com/press/",
  },
  "privacy-policy": {
    description:
      "Read our terms & conditions and discover how we look after your privacy and use your data at YuLife, who we share your data with and how we keep it secure.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Privacy Policy | YuLife",
    url: "https://yulife.com/privacy-policy/",
  },
  rewards: {
    description:
      "Get Amazon Vouchers and gift cards for your staff every time that they take part in healthy or mindful activities. Sign up with YuLife today for Employee Rewards!",
    image: {},
    imageAlt: "yulife-rewards",
    keywords: [],
    noIndex: false,
    title: "Employee Rewards Programs & Employee Perks | YuLife",
    url: "https://yulife.com/rewards/",
  },
  "rewards-policy": {
    description:
      "Get your employees Amazon Vouchers and gift cards every time they're active or mindful. Discover YuLife Employee Rewards & Perks Program!",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Rewards Policy | YuLife",
    url: "https://yulife.com/rewards-policy/",
  },
  sitemap: {
    description:
      "Use the YuLife sitemap to find all the key pages on our website - Find the products and information that you are looking for regarding the life insurance products that we offer.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Sitemap | YuLife",
    url: "https://yulife.com/sitemap/",
  },
  "smart-health": {
    description:
      "YuDoctor virtual GP service, get access to  24/7 unlimited access to GPs by video, phone, and message consultation",
    image: {},
    imageAlt: "virtual-doctor",
    keywords: [],
    noIndex: false,
    title: "Virtual GP & Doctor | Available 24/7 Online | YuLife",
    url: "https://yulife.com/smart-health/",
  },
  "success-stories": {
    description:
      "Read our Success Stories – See how clients across the UK are benefitting from YuLife and how their employees are maximising their physical, mental and financial wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | YuLife",
    url: "https://yulife.com/success-stories/",
  },
  team: {
    description:
      "Read our Success Stories – See how clients across the UK are using YuLife to maximise the financial, mental and physical wellbeing of their employees.",
    image: {},
    imageAlt: "yulife-team-photo",
    keywords: [],
    noIndex: false,
    title: "Meet the Team | YuLife",
    url: "https://yulife.com/team/",
  },
  wills: {
    description:
      "Meet The Team at YuLife – We turn financial products into a force for good and help companies provide their employees with rewards that allow them to grow.",
    image: {},
    imageAlt: "will-writing",
    keywords: [],
    noIndex: false,
    title: "Free Will Writing Service | YuLife",
    url: "https://yulife.com/wills/",
  },
  quiz: {
    description: "Take this ten-question quiz to find out!",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1611236422/featured_images/featured-business-leader-personality.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1611236422/featured_images/featured-business-leader-personality.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1611236422/featured_images/featured-business-leader-personality.png",
    },
    imageAlt: "what-is-your-business-leader-personality",
    keywords: [],
    noIndex: false,
    title: "What are your defining leadership traits, and how do they affect your team? | YuLife",
    url: "https://yulife.com/quiz/leader-personality/",
  },
  blog_health: {
    description:
      "Follow the YuLife blog. Discover articles and insights on how to build a blossoming workplace culture around finances, physical health and wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Our Blog | Health | YuLife",
    url: "https://yulife.com/blog/health/",
  },
  blog_insurance: {
    description:
      "Follow the YuLife blog. Discover articles and insights on how to build a blossoming workplace culture around finances, physical health and wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Our Blog | Insurance | YuLife",
    url: "https://yulife.com/blog/insurance/",
  },
  blog_news: {
    description:
      "Follow the YuLife blog. Discover articles and insights on how to build a blossoming workplace culture around finances, physical health and wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Our Blog | News | YuLife",
    url: "https://yulife.com/blog/news/",
  },
  blog_work: {
    description:
      "Follow the YuLife blog. Discover articles and insights on how to build a blossoming workplace culture around finances, physical health and wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Our Blog | Work | YuLife",
    url: "https://yulife.com/blog/work/",
  },
  events_health: {
    description:
      "Upcoming Health and Wellness Events – YuLife puts on regular health and wellbeing events and breakfasts featuring great speakers and industry experts.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Health and Wellbeing Events in London and UK | YuLife",
    url: "https://yulife.com/events/health/",
  },
  events_work: {
    description:
      "Upcoming Health and Wellness Events – YuLife puts on regular health and wellbeing events and breakfasts featuring great speakers and industry experts.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Health and Wellbeing Events in London and UK | YuLife",
    url: "https://yulife.com/events/work/",
  },
  "success-stories_finance": {
    description:
      "Read our Success Stories – See how clients in the financial sector are benefitting from YuLife and improving the lives of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Finance | YuLife",
    url: "https://yulife.com/success-stories/finance/",
  },
  "success-stories_food-and-drink": {
    description:
      "Read our Success Stories – See how our clients in the food and drinks sector are benefitting from YuLife services and rewards.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Food and Drink | YuLife",
    url: "https://yulife.com/success-stories/food-and-drink/",
  },
  "success-stories_hr": {
    description:
      "Read our Success Stories – See how our HR clients across the UK are using YuLife services to promote the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | HR | YuLife",
    url: "https://yulife.com/success-stories/hr/",
  },
  "success-stories_legal": {
    description:
      "Read our Success Stories – See how our clients in the legal department are benefitting from YuLife in maximising the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Legal | YuLife",
    url: "https://yulife.com/success-stories/legal/",
  },
  "success-stories_media": {
    description:
      "Read our Success Stories – See how our media clients are taking advantage of YuLife services to maximise the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Media | YuLife",
    url: "https://yulife.com/success-stories/media/",
  },
  "success-stories_property": {
    description:
      "Read the Success Stories of our property clients and see how they're benefitting from YuLife in maximising the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Property | YuLife",
    url: "https://yulife.com/success-stories/property/",
  },
  "success-stories_public-sector": {
    description:
      "Read our Success Stories – See how YuLife public sector clients use our services to promote the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Public Sector | YuLife",
    url: "https://yulife.com/success-stories/public-sector/",
  },
  "success-stories_recruitment": {
    description:
      "Read our Success Stories – See how clients in the recruitment sector across the UK are benefitting from YuLife services.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Recruitment | YuLife",
    url: "https://yulife.com/success-stories/recruitment/",
  },
  "success-stories_retail": {
    description:
      "Read our Success Stories – See how retail clients are benefitting from YuLife and how our services allow maximising the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Retail | YuLife",
    url: "https://yulife.com/success-stories/retail/",
  },
  "success-stories_research": {
    description:
      "Read the Success Stories - See how our research clients are benefitting from YuLife in maximising the wellbeing of their employees.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Research | YuLife",
    url: "https://yulife.com/success-stories/research/",
  },
  "success-stories_technology": {
    description:
      "Read our Success Stories – See how our tech clients benefit from YuLife services to maximise their employees' wellbeing.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Success Stories | Technology | YuLife",
    url: "https://yulife.com/success-stories/technology/",
  },
  ycp20200117_calm: {
    description:
      "Discover Calm, the #1 app for meditation and mindfulness. Lower stress and sleep better with 100+ guided meditations.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1622811387/featured_images/og-image-calm.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1622811387/featured_images/og-image-calm.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1622811387/featured_images/og-image-calm.png",
    },
    keywords: [],
    noIndex: true,
    title: "Calm | YuLife",
    url: "https://yulife.com/ycp20200117/calm/",
  },
  "wellbeing-hub": {
    description:
      "YuLife’s Wellbeing Hub brings your benefits together, allows you to run key iniatives and increases engagement with your benefits. Find out how.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1592578127/featured_images/og-image-home.png",
    },
    keywords: [],
    noIndex: false,
    title: "The Employee Wellbeing Hub | YuLife",
    url: "https://yulife.com/features/wellbeing-hub",
  },
  "group-dental-insurance": {
    description:
      "Group Dental Insurance is a highly valued employee benefit helping employees take care of their oral health, and control rising dental costs – but with YuLife, your people get more than just insurance. This is how it works.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Group Dental Insurance | YuLife & Bupa",
    url: "https://yulife.com/insurance/group-dental-insurance/",
  },
  "virtual-gp-services": {
    description:
      "Through YuLife's YuDoctor, give your employees and their dependents the 24/7, 365-days-a-year medical support they need, boosting health, happiness and productivity.",
    image: {},
    keywords: [
      "virtual doctor",
      "remote doctor",
      "virtual gp",
      "second medical opinion",
      "telemedicine",
    ],
    noIndex: false,
    title: "Virtual GP and healthcare services",
    url: "https://yulife.com/features/virtual-gp-services/",
  },
  portal: {
    description:
      "You're in control of your employee benefits, insurance and management from enrolment through engagement campaigns to reporting. Find health insights, ESG and social impact results, and more.",
    image: {},
    keywords: ["technology", "platform", "employer portal", "management portal", "HR portal"],
    noIndex: false,
    title: "The YuLife Employer Portal",
    url: "https://yulife.com/features/employer-portal/",
  },
  YuStore: {
    description:
      "Get discounted access to popular wellbeing apps and elevate engagement through YuLife’s YuStore.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "YuStore | YuLife",
    url: "https://yulife.com/features/yustore/",
  },
  "employee-engagement": {
    description:
      "YuLife leverages gamification and healthy habits to increase employee engagement with wellbeing, employee benefits and your business. Here are the results.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Employee Engagement | YuLife",
    url: "https://yulife.com/features/employee-engagement/",
  },
  "employee-benefits": {
    description:
      "YuLife leverages gamification and healthy habits to increase employee engagement with wellbeing, employee benefits and your business. Here are the results.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Employee Benefits | YuLife",
    url: "https://yulife.com/features/employee-benefits/",
  },
  "app-only": {
    description:
      "Meet YuLife. Employee health and wellbeing benefits, on an award-winning employee benefits app. Find out how we’re delivering employee engagement.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Employee Benefits Platform from YuLife",
    url: "https://yulife.com/insurance/app-only/",
  },
  "social-impact": {
    description:
      "Meet YuLife. Employee health and wellbeing benefits, on an award-winning employee benefits app. Find out how we’re delivering employee engagement.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Social Impact Benefits | YuLife",
    url: "https://yulife.com/features/social-impact-benefits/",
  },
  "group-insurance": {
    description:
      "Group Insurance as an employee benefit with YuLife – what group insurance is, what’s included, and why its integral to a well-rounded health and wellbeing programme.",
    image: {},
    keywords: ["insurance", "group"],
    noIndex: false,
    title: "Group insurance from YuLife",
    url: "https://yulife.com/insurance/group-insurance/",
  },
  "hr-insights-new": {
    description:
      "YuLife’s HR portal gives at-a-glance insights for people leaders to report on the metrics that matter most.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "HR Insights & Reporting | YuLife",
    url: "https://yulife.com/features/social-impact-benefits/",
  },
  "whats-included": {
    description:
      "YuLife brings group insurance, employee wellbeing benefits and rewards together in one powerful platform. Here’s how it works.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "What's Included | YuLife",
    url: "https://yulife.com/whats-included/",
  },
  "group-health-insurance": {
    description:
      "Group Health insurance helps your employees live healthier lives - but with YuLife, your people get more than just group health insurance.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1693909459/featured_images/featured-health-insurance.png",
    },
    keywords: [],
    noIndex: false,
    title: "Group Health Insurance | YuLife & Bupa",
    url: "https://yulife.com/insurance/group-health-insurance/",
  },
  "about-us": {
    description:
      "About YuLife – We are a life insurance company that by offering great rewards and putting health and wellbeing first is maximising employee engagement.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "About Us | YuLife",
    url: "http://yulife.com/insurance/about-us/",
  },
  "global-rewards-and-services": {
    description:
      "Find out which of YuLife’s workplace wellbeing benefits are available to your team with this all-in-one, global wellbeing platform.",
    image: {},
    keywords: [],
    noIndex: true,
    title: "Global Rewards & Services | YuLife",
    url: "http://yulife.com/features/global-rewards-and-services/",
  },
  "adviser-contact": {
    description:
      "Reach out to our team and learn more about how YuLife can bring new and innovative insurance to your clients.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Advisers: Contact | YuLife",
    url: "http://yulife.com/adviser/contact-us/",
  },
  "adviser-contact-success": {
    description:
      "You’ve reached out to our team! We’ll be in touch shortly about how YuLife can bring new and innovative insurance to your clients.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "Advisers: Contact Success | YuLife",
    url: "http://yulife.com/adviser/contact-us/success/",
  },
  adviser: {
    description:
      "Your clients deserve the best cover. Our #1-rated insurance & benefits product is a win-win that will give your clients more for their money. Inspire life and rewards wellbeing with YuLife.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1709575129/featured_images/featured-advisers.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1709575129/featured_images/featured-advisers.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1709575129/featured_images/featured-advisers.png",
    },
    keywords: [],
    noIndex: false,
    title: "Advisers | YuLife",
    url: "http://yulife.com/adviser/",
  },
  "private-health-insurance": {
    description:
      "Private Health Insurance helps your employees live healthier lives – but with YuLife, your people get more than just company health insurance. This is how it works.",
    keywords: [],
    noIndex: false,
    title: "Private Health Insurance | YuLife & Bupa",
    url: "http://yulife.com/private-health-insurance/",
  },
  "news-and-events": {
    description:
      "Get the latest news on product updates, events, achievements and partnerships around the YuLife app.",
    image: {},
    keywords: [],
    noIndex: false,
    title: "News & Events | YuLife",
    url: "https://yulife.com/news-and-events/",
  },
  "resource-hub": {
    description:
      "Discover YuLife's case studies, blog posts, events and announcements in this categorised Resource Hub.",
    image: {
      feature_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1713879432/featured_images/Resource_Hub.png",
      og_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1713879432/featured_images/Resource_Hub.png",
      twitter_image:
        "https://res.cloudinary.com/yu-life/image/upload/v1713879432/featured_images/Resource_Hub.png",
    },
    keywords: [],
    noIndex: false,
    title: "Resource Hub | YuLife",
    url: "https://yulife.com/resource-hub/",
  },
};
