import { metadata, metadataJP, metadataSA, metadataUS } from "utils/metadata";
import { useLocale } from "./useLocale";

export const useMetadata = (page: string) => {
  const { locale } = useLocale();

  switch (locale) {
    case "us":
      return metadataUS[page];
    case "za":
      return metadataSA[page];
      case "jp":
      return metadataJP[page];
    default:
      return metadata[page];
  }
};
